import { FC, PropsWithChildren, useEffect } from 'react';
import {
	TabNavigationStyled,
	TabNavigationWrapperStyled,
} from '@ui/tab-navigation/TabNavigation.styled';
import { TabButton, TabButtonProps } from '@ui/tab-button';
import { useIsElementScroll } from '@legacyApp/hooks/window/useIsElementScroll';
import { useInitScrollTo } from '@common/hooks/useInitScrollTo';
import { useMultiRef } from '@common/hooks/useMultiRef';
import { usePrevious } from '@legacyApp/hooks/render/usePrevious';

export type TabNavigationProps = PropsWithChildren<{
	tabs: Array<TabButtonProps>;
	activeTabId: TabButtonProps['id'];
	onClick?: TabButtonProps['onClick'];
	small?: TabButtonProps['small'];
}>;

export const TabNavigation: FC<TabNavigationProps> = ({
	tabs,
	activeTabId,
	onClick,
	children,
	small,
}) => {
	const prevId = usePrevious(activeTabId);

	const { ref, isScroll } = useIsElementScroll();

	const { handleScroll, elementRef, boxRef } = useInitScrollTo({
		leftPadding: 50,
	});

	const multiRef = useMultiRef([boxRef, ref]);

	useEffect(() => {
		if (activeTabId !== prevId) {
			handleScroll();
		}
	}, [activeTabId, handleScroll, prevId]);

	if (!tabs?.length) {
		return null;
	}

	return (
		<TabNavigationWrapperStyled $isScroll={isScroll}>
			<TabNavigationStyled ref={multiRef}>
				{tabs.map((tab) => {
					if (tab.doNotShowInTabs) {
						return;
					}

					return (
						<TabButton
							refElement={activeTabId === tab.id ? elementRef : null}
							key={tab.id}
							{...tab}
							active={activeTabId === tab.id}
							onClick={tab.onClick || onClick}
							small={small}
						/>
					);
				})}
			</TabNavigationStyled>
			{children}
		</TabNavigationWrapperStyled>
	);
};
