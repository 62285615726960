import { FC } from 'react';
import { IconProps } from '@icons/all-bets';
import { Svg } from '@legacyApp/client/components/svg/Svg';
import { useId } from '../hooks/useId';

export const Highroll: FC<IconProps> = ({ fill, fillStop }) => {
	const { get } = useId({ id: 'highroll' });
	const fill_ = fill || 'white';
	const fillStop_ = fillStop || fill;
	return (
		<Svg
			content={
				<>
					<path
						d="M7.1875 7.5C7.70527 7.5 8.125 7.08027 8.125 6.5625C8.125 6.04473 7.70527 5.625 7.1875 5.625C6.66973 5.625 6.25 6.04473 6.25 6.5625C6.25 7.08027 6.66973 7.5 7.1875 7.5Z"
						fill={get().url}
					/>
					<path
						d="M12.8125 7.5C13.3303 7.5 13.75 7.08027 13.75 6.5625C13.75 6.04473 13.3303 5.625 12.8125 5.625C12.2947 5.625 11.875 6.04473 11.875 6.5625C11.875 7.08027 12.2947 7.5 12.8125 7.5Z"
						fill={get().url}
					/>
					<path
						d="M8.125 10C8.125 10.5178 7.70527 10.9375 7.1875 10.9375C6.66973 10.9375 6.25 10.5178 6.25 10C6.25 9.48223 6.66973 9.0625 7.1875 9.0625C7.70527 9.0625 8.125 9.48223 8.125 10Z"
						fill={get().url}
					/>
					<path
						d="M12.8125 10.9375C13.3303 10.9375 13.75 10.5178 13.75 10C13.75 9.48223 13.3303 9.0625 12.8125 9.0625C12.2947 9.0625 11.875 9.48223 11.875 10C11.875 10.5178 12.2947 10.9375 12.8125 10.9375Z"
						fill={get().url}
					/>
					<path
						d="M8.125 13.4375C8.125 13.9553 7.70527 14.375 7.1875 14.375C6.66973 14.375 6.25 13.9553 6.25 13.4375C6.25 12.9197 6.66973 12.5 7.1875 12.5C7.70527 12.5 8.125 12.9197 8.125 13.4375Z"
						fill={get().url}
					/>
					<path
						d="M12.8125 14.375C13.3303 14.375 13.75 13.9553 13.75 13.4375C13.75 12.9197 13.3303 12.5 12.8125 12.5C12.2947 12.5 11.875 12.9197 11.875 13.4375C11.875 13.9553 12.2947 14.375 12.8125 14.375Z"
						fill={get().url}
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M5 2.625C3.68832 2.625 2.625 3.68832 2.625 5V15C2.625 16.3117 3.68832 17.375 5 17.375H15C16.3117 17.375 17.375 16.3117 17.375 15V5C17.375 3.68832 16.3117 2.625 15 2.625H5ZM3.625 5C3.625 4.24061 4.24061 3.625 5 3.625H15C15.7594 3.625 16.375 4.24061 16.375 5V15C16.375 15.7594 15.7594 16.375 15 16.375H5C4.24061 16.375 3.625 15.7594 3.625 15V5Z"
						fill={get().url}
					/>
					<defs>
						<linearGradient
							id={get().id}
							x1="17.375"
							y1="2.625"
							x2="2.625"
							y2="17.375"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor={fill_} />
							<stop offset="1" stopColor={fillStop_} />
						</linearGradient>
					</defs>
				</>
			}
			width={20}
			height={20}
		/>
	);
};
