import { useCallback, useRef, useState } from 'react';
import { useWindowSize } from './useWindowSize';

export const useIsElementScroll = () => {
	const ref = useRef(null);
	const [isScroll, setIsScroll] = useState(true);

	const resizeCallback = useCallback(() => {
		if (!ref.current) {
			return;
		}
		const { scrollWidth, clientWidth } = ref.current;
		const isScroll_ = scrollWidth > clientWidth;
		if (isScroll_ !== isScroll) {
			setIsScroll(isScroll_);
		}
	}, [isScroll]);

	useWindowSize(resizeCallback);

	return {
		ref,
		isScroll,
	};
};
