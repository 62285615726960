import styled from 'styled-components';
import { TabButtonStyled } from '@ui/tab-button';

export const TabNavigationStyled = styled.div`
	//background-color: var(--color-dark-700);
	border-radius: 70px;
	display: flex;
	flex-flow: row nowrap;
	overflow: auto;
	padding: 16px;

	${TabButtonStyled}:not(:first-of-type) {
		margin-left: 5px;
	}

	@media (max-width: ${(props) => props.theme.media_v2.max_mobile_medium}) {
		padding: 12px;
	}
`;

export const TabNavigationWrapperStyled = styled.div<{
	$isScroll?: boolean;
}>`
	display: flex;
	flex-flow: row nowrap;
	max-width: 100%;
	overflow: auto;
	position: relative;

	&:before {
		background-color: var(--color-dark-700);
		border-radius: 70px;
		content: '';
		height: ${(props) => (props.$isScroll ? 'calc(100% - 0px)' : '100%')};
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: -1;
	}
`;
