import { useCallback, useEffect, useState } from 'react';
import { useWindowEventListener } from '@common/hooks/useEventListener';

type Callback = (values: [number, number]) => void;

export const useWindowSize = (callback: Callback) => {
	const [size, setSize] = useState([0, 0]);
	const updateSize = useCallback(() => {
		if (size[0] === window.innerWidth && size[1] === window.innerHeight) {
			return;
		}
		setSize([window.innerWidth, window.innerHeight]);
		if (!callback) {
			return;
		}
		callback([window.innerWidth, window.innerHeight]);
	}, [callback, size]);

	useWindowEventListener('resize', updateSize);

	useEffect(() => {
		updateSize();
	}, [updateSize]);
	return size;
};
