import { useCallback, useRef } from 'react';

export interface UseInitScrollTo {
	leftPadding?: number;
	topPadding?: number;
}

export const useInitScrollTo = ({
	leftPadding,
	topPadding,
}: UseInitScrollTo) => {
	const boxRef = useRef(null);
	const elementRef = useRef(null);

	const handleScroll = useCallback(() => {
		// console.log('useInitScrollTo', { elementRef: elementRef.current, boxRef: boxRef.current });
		if (!elementRef?.current || !boxRef?.current) {
			return;
		}
		boxRef.current.scrollTo({
			top: elementRef.current.offsetTop - (topPadding || 0),
			left: elementRef.current.offsetLeft - (leftPadding || 0),
			behavior: 'smooth',
		});
	}, [leftPadding, topPadding]);

	return {
		handleScroll,
		boxRef,
		elementRef,
	};
};
