import { MutableRefObject, useEffect, useRef } from 'react';

export const useMultiRef = (
	refs: Array<MutableRefObject<any>>,
): MutableRefObject<any> => {
	const ref = useRef(null);

	useEffect(
		function () {
			refs.map((ref_) => {
				ref_.current = ref.current;
			});
		},
		[ref.current],
	);

	return ref;
};
