import { CSSProperties } from 'react';
import { Svg } from '@legacyApp/client/components/svg/Svg';
import { useId } from '../hooks/useId';

export interface IconProps {
	fill?: string;
	fillStop?: string;
	fillMiddle?: string;
	width?: number;
	height?: number;
	active?: boolean;
	className?: string;
	style?: CSSProperties;
}

export const AllBets = ({ fill, fillStop }: IconProps) => {
	const { get } = useId({ id: 'all_bets' });
	const color = fill || 'white';
	return Svg({
		content: (
			<>
				<mask
					id={get(1).id}
					style={{ maskType: 'alpha' }}
					maskUnits="userSpaceOnUse"
					x="0"
					y="3"
					width="20"
					height="14"
				>
					<path
						d="M7.5 9.375C10.9518 9.375 13.75 8.1158 13.75 6.5625C13.75 5.0092 10.9518 3.75 7.5 3.75C4.04822 3.75 1.25 5.0092 1.25 6.5625C1.25 8.1158 4.04822 9.375 7.5 9.375Z"
						stroke="black"
						fill="transparent"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M1.25 6.5625V9.6875C1.25 11.2422 4.04687 12.5 7.5 12.5C10.9531 12.5 13.75 11.2422 13.75 9.6875V6.5625"
						stroke="black"
						fill="transparent"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M5 9.14062V12.2656"
						stroke="black"
						fill="transparent"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M13.75 7.55469C16.6016 7.82031 18.75 8.95313 18.75 10.3125C18.75 11.8672 15.9531 13.125 12.5 13.125C10.9687 13.125 9.5625 12.875 8.47656 12.4688"
						stroke="black"
						fill="transparent"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M6.25 12.4453V13.4375C6.25 14.9922 9.04687 16.25 12.5 16.25C15.9531 16.25 18.75 14.9922 18.75 13.4375V10.3125"
						stroke="black"
						fill="transparent"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M15 12.8906V16.0156"
						stroke="black"
						fill="transparent"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M10 9.14062V16.0156"
						stroke="black"
						fill="transparent"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</mask>
				<g mask={get(1).url}>
					<rect y="-1" width="20" height="20" fill={get().url} />
				</g>
				<defs>
					<linearGradient
						id={get().id}
						x1="20"
						y1="-1"
						x2="-1.78814e-06"
						y2="19"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor={fillStop || color} />
						<stop offset="1" stopColor={color} />
					</linearGradient>
				</defs>
			</>
		),
		width: 20,
		height: 20,
	});
};
