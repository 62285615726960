import React, {
	ComponentType,
	FC,
	ReactNode,
	useCallback,
	useState,
} from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { TabNavigation } from '@ui/tab-navigation';
import { TabButtonProps } from '@ui/tab-button';
import { TabNavigationWrapperStyled } from '@ui/tab-navigation/TabNavigation.styled';
import { CSSClassNameButton } from '@ui/button';
import { AllBets } from '@icons/all-bets';
import { WolfRace } from '@icons/wolf-race';
import { Highroll } from '@icons/highroll';
import { MyBets } from '@icons/my-bets';
import { RaceResults } from '@modules/race/components/raceResults';
import { useUserIsLogged } from '@common/selectors/user.selectors';
import { useRouterQueryKey } from '@common/selectors/router.selectors';
import { TABLE_ID, TableIdType } from '@modules/transactions/constants/TableId';
import { useStateEffect } from '@common/hooks/useStateEffect';
import { useOnMount } from '@common/hooks/useOnMount';
import transactionService from '@modules/transactions/transactionsService';
import { updateUrlQuery } from '@legacyApp/client/store/app/app.actions';
import { TransactionsTable } from '@legacyApp/client/components/transactionsTable/transactionsTable';
import { MyBetsTable } from '@legacyApp/client/components/transactionsTable/myBets/myBetsTable';
import { Trans } from '@legacyApp/client/modules/translation/translate';

const DEFAULT_LIMIT = 10;

export interface Table {
	id: TableIdType;
	name: string | ReactNode;
	isLogged?: boolean;
	component?:
		| FC<{
				limit?: number;
				pagination?: boolean;
		  }>
		| ComponentType<{
				limit?: number;
				pagination?: boolean;
		  }>;
	loadingId?: string;
	noWrap?: boolean;
	disabled?: boolean;
	icon: TabButtonProps['icon'];
}

const TABLES: Array<Table> = [
	{
		id: TABLE_ID.myBets,
		name: <Trans label={'my bets'} />,
		isLogged: true,
		icon: MyBets,
		component: (props) => <MyBetsTable limit={props.limit} />,
	},
	{
		id: TABLE_ID.allBets,
		name: <Trans label={'All bets'} />,
		loadingId: 'GetTransactions',
		icon: AllBets,
	},
	{
		id: TABLE_ID.highRolls,
		name: <Trans label={'High Rollers'} />,
		loadingId: 'highGetTransactions',
		icon: Highroll,
	},
	{
		id: TABLE_ID.raceResults,
		icon: WolfRace,
		name: <Trans label={'Wolf Race'} />,
		component: () => <RaceResults />,
		noWrap: true,
	},
];

const getTableContent = (table) => {
	return table?.component ? (
		table.component({
			limit: DEFAULT_LIMIT,
			pagination: false,
		})
	) : (
		<TransactionsTable
			id={table?.id}
			limit={DEFAULT_LIMIT}
			noResultsText={'No bets'}
			loadingId={table && table.loadingId}
		/>
	);
};

const useAvailableTables = () => {
	const isLogged = useUserIsLogged();
	const getTables = useCallback(() => {
		return TABLES.filter((table) => {
			if (table.disabled) {
				return false;
			}
			if (!isLogged) {
				return !table.isLogged;
			}
			return true;
		});
	}, [isLogged]);
	return useStateEffect(getTables, (a, b) =>
		transactionService.isListDifferent(a, b, ['id']),
	);
};

const useActiveTableContent = (activeTable: Table) => {
	const getActiveTableContent = useCallback(() => {
		return {
			id: activeTable.id,
			content: getTableContent(activeTable),
		};
	}, [activeTable]);
	return useStateEffect(getActiveTableContent, (a, b) => a.id !== b.id);
};

const useTabs = (availableTables: Array<Table>) => {
	const getTabs = useCallback(() => {
		return availableTables.map(
			(table): TabButtonProps => ({
				id: table.id,
				label: table.name,
				icon: table.icon,
			}),
		);
	}, [availableTables]);
	return useStateEffect(getTabs, (a, b) =>
		transactionService.isListDifferent(a, b, ['id']),
	);
};

export const TransactionsHistory: FC = () => {
	const dispatch = useDispatch();
	const queryTable = useRouterQueryKey('table');
	const availableTables = useAvailableTables();
	const tabs = useTabs(availableTables);
	const [activeTable, setActiveTable_] = useState<Table>(availableTables[0]);
	const activeTableContent = useActiveTableContent(activeTable);

	const handleChangeTable = useCallback(
		(table: Table = null) => {
			const table_: Table = table || activeTable;
			if (table_.id === activeTable?.id) {
				return;
			}
			// console.log('handleChangeTable', {
			// 	table,
			// 	activeTable,
			// 	table_,
			// 	availableTables,
			// });
			const tableId = table_?.id === availableTables[0].id ? null : table_?.id;
			if ((tableId || (!tableId && queryTable)) && queryTable !== tableId) {
				dispatch(updateUrlQuery({ table: tableId }));
			}
			setActiveTable_(table_);
		},
		[activeTable, availableTables, queryTable, dispatch],
	);

	const handleChangeTableId = useCallback(
		(event, { id: tableId }) => {
			const table = availableTables.find((table) => table.id === tableId);
			handleChangeTable(table);
		},
		[availableTables, handleChangeTable],
	);

	useOnMount(() => {
		if (queryTable) {
			const table = availableTables.find((el) => el.id === queryTable) || null;
			if (table) {
				handleChangeTable(table);
			}
		}
	});

	return (
		<TransactionsHistoryWrapper>
			<TabNavigation
				tabs={tabs}
				onClick={handleChangeTableId}
				activeTabId={activeTable?.id}
			/>
			{activeTableContent.content}
		</TransactionsHistoryWrapper>
	);
};

const TransactionsHistoryWrapper = styled.div`
	float: left;
	width: 100%;

	${TabNavigationWrapperStyled} {
		margin-bottom: 36px;
	}

	.${CSSClassNameButton} {
		text-transform: capitalize;
	}

	@media (max-width: ${(props) => props.theme.media_v2.max_mobile_medium}) {
		${TabNavigationWrapperStyled} {
			margin-bottom: 24px;
		}
	}
`;
